import React, { FC } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { PrivateRoute } from 'routes/PrivateRoute'
import { Routes } from 'routes/routes'
import Auth from 'pages/auth'
import MainPage from 'pages/main'
import RestorePassword from 'pages/restorePassword'
import AppPage from 'pages/app'
import NotFound from 'pages/404'

export const RootRoute: FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={Routes.auth}>
          <Auth />
        </Route>

        <Route path={Routes.restorePassword}>
          <RestorePassword />
        </Route>

        <Route path={Routes.app}>
          <AppPage />
        </Route>

        <PrivateRoute path={Routes.home}>
          <MainPage />
        </PrivateRoute>

        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}
