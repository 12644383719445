import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import BarLoader from 'react-spinners/BarLoader'
import { css } from '@emotion/core'
import { useSelector } from 'react-redux'
import {
  getKaraokeFilesCount,
  getKaraokeFilesTotalCount,
  getKaraokeFilesUploading,
  getXlsFileOriginalName,
  getXlsFileParsing,
  getFileUploading,
} from 'store/modules/file/selectors'
import { colors } from 'styles/colors'

import rollUp from 'assets/icons/rollUp.svg'

const useStyles = createUseStyles({
  wrapper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    left: 25,
    bottom: 25,
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.5)',
    backgroundColor: colors.white,
    zIndex: 10,
    borderRadius: 4,
    padding: 20,
  },
  rollUp: {
    alignSelf: 'flex-end',
    width: 16,
    height: 10,
    border: 'none',
    backgroundImage: `url(${rollUp})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'transparent',
    outline: 'none',
    cursor: 'pointer',
    marginBottom: 20,
  },
  text: {
    display: 'block',
    fontSize: 18,
    fontWeight: 500,
    color: colors.graySecond,
    marginBottom: 10,
  },
  fileName: {
    color: colors.blueMain,
  },
})

const overrideSpinnerStyles = css`
  height: 5px;
  width: 350px;
`

export const PopUpLoadingFiles: FC = () => {
  const classes = useStyles()

  const loadingFile = useSelector(getFileUploading)
  const parsingXlsFile = useSelector(getXlsFileParsing)
  const originalName = useSelector(getXlsFileOriginalName)
  const karaokeFileLoading = useSelector(getKaraokeFilesUploading)
  const karaokeFileCount = useSelector(getKaraokeFilesCount)
  const karaokeFileTotalCount = useSelector(getKaraokeFilesTotalCount)

  return (
    <div className={classes.wrapper}>
      {/*<button className={classes.rollUp} />*/}
      {loadingFile && <span className={classes.text}>Загрузка файла</span>}
      {parsingXlsFile && (
        <span className={classes.text}>
          Обработка файла &nbsp; <span className={classes.fileName}>{originalName}</span>
        </span>
      )}
      {karaokeFileLoading && (
        <span className={classes.text}>
          Загрузка караоке-файлов {karaokeFileCount} из {karaokeFileTotalCount}
        </span>
      )}
      <BarLoader css={overrideSpinnerStyles} color={'#123abc'} loading={true} />
    </div>
  )
}
