import { configureStore } from '@reduxjs/toolkit'
// import storage from 'redux-persist/lib/storage'
// import { persistReducer } from 'redux-persist'

import { rootReducer } from 'store/root-reducer'

// const persistConfig = {
//   key: 'root',
//   storage,
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: rootReducer,
  // reducer: persistedReducer,
})

export type AppDispatch = typeof store.dispatch
export type TState = ReturnType<typeof rootReducer>
