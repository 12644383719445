import React, { FC, FunctionComponent, SVGProps } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { colors } from 'styles/colors'
import styled, { css } from 'styled-components'

type TProps = {
  title: string
  to: string
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
  onClick?: () => void
}

export const NavItem: FC<TProps> = (link) => {
  const location = useLocation()
  const active = location.pathname.split('/')[1] === link.to.split('/')[1]

  return (
    <StyledLink $active={active} to={link.to} onClick={link.onClick}>
      <IconWrapper>
        <link.icon />
      </IconWrapper>
      <Text $active={active}>{link.title}</Text>
    </StyledLink>
  )
}

type TStyledProps = {
  $active: boolean
}

const Text = styled.span`
  width: 100%;
  padding: 2px 0 2px 10px;
  color: ${colors.graySecond};
  ${(props: TStyledProps) =>
    props.$active &&
    css`
      border-right: 4px solid ${colors.blueMain};
      color: ${colors.blueMain};
    `}
}
`
const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  padding: 10px 0 10px 10px;
  &:last-child {
    margin-top: 35px;
  }
  ${(props: TStyledProps) =>
    props.$active &&
    css`
      background-color: ${colors.blueHover};
    `}
`
const IconWrapper = styled.div`
  min-width: 32px;
  display: flex;
  align-items: center;
`
