import React, { useEffect } from 'react'
import { RootRoute } from 'routes/RootRoute'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import { getIsLoading } from 'store/modules/loader/selectors'
import { getUserInfo } from 'store/modules/auth/actions'
import { Loader } from 'components/loader/Loder'
import 'react-dadata/dist/react-dadata.css'
import { getPhonogramColumnSettings } from 'store/modules/phonogramms/actions'
import { getCountriesFromServer } from 'store/modules/shared/actions'

export function App() {
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsLoading)

  useEffect(() => {
    const loadingScreen = document.querySelector('#await-app-loading-screen')
    if (loadingScreen) {
      loadingScreen.remove()
    }

    const token = localStorage.getItem('token')
    if (token) {
      dispatch(getUserInfo())
      dispatch(getPhonogramColumnSettings())
    }

    dispatch(getCountriesFromServer())
  }, [])

  return (
    <>
      {isLoading && <Loader showSpinner={isLoading} />}
      <ToastContainer />
      <RootRoute />
    </>
  )
}
