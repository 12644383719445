import axios from 'axios'
import { config } from 'config/config'
import { errorNotify } from 'errors'
import { Routes } from 'routes/routes'
import { store } from 'store/store'
import { setIsLoading } from 'store/modules/loader/reducer'

export const request = axios.create({
  baseURL: config.baseUrl,
  responseType: 'json',
})

request.interceptors.request.use(
  (config) => {
    store.dispatch(setIsLoading(true))

    const token = localStorage.getItem('token')
    if (token) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      config.headers.authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    store.dispatch(setIsLoading(false))
    return Promise.reject(error)
  },
)

request.interceptors.response.use(
  (response) => {
    store.dispatch(setIsLoading(false))
    return response
  },
  function (error) {
    store.dispatch(setIsLoading(false))
    errorNotify(error)
    if (error.response.data.statusCode === 401 || error.response.data.error === 'Access denied') {
      localStorage.removeItem('token')
      location.replace(Routes.auth)
    }
    return Promise.reject(error)
  },
)
