import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Routes } from 'routes/routes'

const NotFound = () => {
  return (
    <Container>
      <Text>Запрашиваемая страница не найдена</Text>
      <Link to={Routes.home}>На главную</Link>
    </Container>
  )
}

export default NotFound

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Text = styled.p`
  font-size: 24px;
  font-weight: 700;
`
