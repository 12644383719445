import React, { FC } from 'react'
import { ReactComponent as IconPhonograms } from 'assets/icons/iconPhonograms.svg'
import { ReactComponent as IconPlaylists } from 'assets/icons/iconPlaylists.svg'
import { ReactComponent as IconCharts } from 'assets/icons/iconCharts.svg'
import { ReactComponent as IconClients } from 'assets/icons/iconClients.svg'
import { ReactComponent as IconStatistics } from 'assets/icons/iconStatistics.svg'
import { ReactComponent as IconUsers } from 'assets/icons/iconUsers.svg'
import { ReactComponent as IconTariff } from 'assets/icons/iconTariff.svg'
import { ReactComponent as IconImages } from 'assets/icons/iconImages.svg'
import { ReactComponent as IconSingers } from 'assets/icons/iconSingers.svg'
import { ReactComponent as IconFAQ } from 'assets/icons/iconFAQ.svg'
import { ReactComponent as IconDocuments } from 'assets/icons/archive.svg'
import { ReactComponent as IconLogs } from 'assets/icons/restoreIcon.svg'
import { Routes } from 'routes/routes'
import { NavItem } from 'pages/main/sideBar/navigation/navItem'
import { useDispatch } from 'react-redux'
import { setSingerRowIndex } from 'store/modules/singers/reducer'

export const Navigation: FC = () => {
  const dispatch = useDispatch()

  const clearSingersRowIndex = () => dispatch(setSingerRowIndex(0))

  const linksArray = [
    { title: 'Фонограммы', to: Routes.home, icon: IconPhonograms },
    { title: 'Плейлисты', to: Routes.playlists, icon: IconPlaylists },
    { title: 'Чарты', to: Routes.charts, icon: IconCharts },
    { title: 'Клиенты', to: Routes.clients, icon: IconClients },
    { title: 'Статистика', to: Routes.statistics, icon: IconStatistics },
    { title: 'Тарифы', to: Routes.tariffs, icon: IconTariff },
    { title: 'Изображения', to: Routes.images, icon: IconImages },
    { title: 'Артисты', to: Routes.singers, icon: IconSingers, onClick: clearSingersRowIndex },
    { title: 'FAQ', to: Routes.faq, icon: IconFAQ },
    { title: 'Документы', to: Routes.documents, icon: IconDocuments },
    { title: 'Логи', to: Routes.logs, icon: IconLogs },
    { title: 'Пользователи', to: Routes.usersAndRoles, icon: IconUsers },
  ]

  return (
    <section>
      {linksArray.map((link, index) => (
        <NavItem key={index} {...link} />
      ))}
    </section>
  )
}
