import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TPhonogramForStatisticsPage, TStatisticsState } from './types'
import { TCompany } from 'store/modules/charts/types'

const initialState: TStatisticsState = {
  statisticsPhonogramList: [],
  companies: [],
}

const userSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatisticsPhonogramList(state: TStatisticsState, action: PayloadAction<TPhonogramForStatisticsPage[]>) {
      state.statisticsPhonogramList = action.payload
    },
    setCompanies(state: TStatisticsState, action: PayloadAction<TCompany[]>) {
      state.companies = action.payload
    },
  },
})

export const { setStatisticsPhonogramList, setCompanies } = userSlice.actions

export default userSlice.reducer
