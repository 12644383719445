import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TOksUser, TRole, TUsersState } from './types'

const initialState: TUsersState = {
  userId: null,
  user: null,
  usersList: [],
  rolesList: [],
  roleInfo: null,
}

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserId(state: TUsersState, action: PayloadAction<string | null>) {
      state.userId = action.payload
    },
    setUser(state: TUsersState, action: PayloadAction<TOksUser | null>) {
      state.user = action.payload
    },
    setUsersList(state: TUsersState, action: PayloadAction<TOksUser[]>) {
      state.usersList = action.payload
    },
    setRolesList(state: TUsersState, action: PayloadAction<TRole[]>) {
      state.rolesList = action.payload
    },
    setRoleInfo(state: TUsersState, action: PayloadAction<TRole | null>) {
      state.roleInfo = action.payload
    },
  },
})

export const { setUserId, setUser, setUsersList, setRolesList, setRoleInfo } = userSlice.actions

export default userSlice.reducer
