import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ESort,
  TFilterByGenresValue,
  TFilterValue,
  TGenreItem,
  TPhonogram,
  TPhonogramsState,
  TPhonogramStatistics,
  TVocalsMelodyFilterValue,
  ContainerSet,
  TExportCatalogColumn,
  TPhonogramsColumnsSave,
  TQuantityInfo,
} from 'store/modules/phonogramms/types'
import { LIMIT } from 'api/const'
import { tableTitles } from 'pages/phonograms/tableParams'

const initialState: TPhonogramsState = {
  rowIndex: 0,
  firstUpdate: true,
  phonogramID: null,
  phonogramsList: [],
  phonogramsListHasMore: true,
  phonogramsListForPlaylists: [],
  phonogramsListHasMoreForPlaylists: true,
  phonogramByID: null,
  phonogramByIDIsLoad: false,
  editPhonogramByIDSuccess: false,
  createPhonogramSuccess: false,
  phonogramsLoadOffset: LIMIT,
  phonogramsLoadOffsetForPlaylists: LIMIT,
  showValidateFileNameWindow: false,
  nextCatalogPhonogramNumber: null,
  genresList: [],
  navbarWidth: 240,
  buildingKaraokeFile: false,
  buildingKaraokeFileName: null,
  karaokeFileIsBuild: false,
  buildingKaraokeFileId: null,
  tempLinkBuildKaraokeFile: null,
  fileDeleting: false,
  phonogramStatistics: null,
  genreSortParams: { label: 'Все жанры', value: undefined },
  searchParams: '',
  deletedPhonogramsFilterValue: { label: 'Все', value: undefined },
  approvedFilterValue: { label: 'Все', value: undefined },
  hasMusicFileFilterValue: { label: 'Все', value: undefined },
  hasLyricsFileFilterValue: { label: 'Все', value: undefined },
  draftFilterValue: { label: 'Все', value: undefined },
  vocalsMelodyFilterValue: { label: 'Все', value: undefined },
  orderByColumn: 'catalogPhonogramNumber',
  orderBy: ESort.ASC,
  runFilters: false,
  selectedContainerSet: null,
  unsavedContainerSets: [],
  exportCatalogColumns: [],
  phonogramsColumns: { base: [], additional: [] },
  quantityInfo: null,
}

const userSlice = createSlice({
  name: 'phonograms',
  initialState,
  reducers: {
    setRowIndex(state: TPhonogramsState, action: PayloadAction<number>) {
      state.rowIndex = action.payload
    },
    setFirstUpdate(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.firstUpdate = action.payload
    },
    setPhonogramID(state: TPhonogramsState, action: PayloadAction<string | null>) {
      state.phonogramID = action.payload
    },
    setPhonogramsList(state: TPhonogramsState, action: PayloadAction<Array<TPhonogram>>) {
      state.phonogramsList = action.payload
    },
    setPhonogramsListOnScroll(state: TPhonogramsState, action: PayloadAction<Array<TPhonogram>>) {
      state.phonogramsList = [...state.phonogramsList, ...action.payload]
    },
    replacePhonogramInPhonogramList(state: TPhonogramsState, action: PayloadAction<TPhonogram>) {
      state.phonogramsList = state.phonogramsList.map((p) => {
        if (p.id === action.payload.id) {
          return action.payload
        }
        return p
      })
    },
    setPhonogramsLoadOffset(state: TPhonogramsState, action: PayloadAction<number>) {
      state.phonogramsLoadOffset = action.payload
    },
    setPhonogramsListHasMore(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.phonogramsListHasMore = action.payload
    },
    setPhonogramByID(state: TPhonogramsState, action: PayloadAction<TPhonogram | null>) {
      state.phonogramByID = action.payload
    },
    setPhonogramByIDIsLoad(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.phonogramByIDIsLoad = action.payload
    },
    setEditPhonogramByIDSuccess(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.editPhonogramByIDSuccess = action.payload
    },
    setCreatePhonogramSuccess(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.createPhonogramSuccess = action.payload
    },
    setShowValidateFileNameWindow(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.showValidateFileNameWindow = action.payload
    },
    setNextCatalogPhonogramNumber(state: TPhonogramsState, action: PayloadAction<number | null>) {
      state.nextCatalogPhonogramNumber = action.payload
    },
    setGenreList(state: TPhonogramsState, action: PayloadAction<TGenreItem[]>) {
      state.genresList = action.payload
    },
    setNavbarWidth(state: TPhonogramsState, action: PayloadAction<number>) {
      state.navbarWidth = action.payload
    },
    setBuildingKaraokeFile(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.buildingKaraokeFile = action.payload
    },
    setKaraokeFileIsBuild(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.karaokeFileIsBuild = action.payload
    },
    setBuildKaraokeFileId(state: TPhonogramsState, action: PayloadAction<string | null>) {
      state.buildingKaraokeFileId = action.payload
    },

    setBuildKaraokeFileName(state: TPhonogramsState, action: PayloadAction<string | null>) {
      state.buildingKaraokeFileName = action.payload
    },

    setTempLinkBuildKaraokeFile(state: TPhonogramsState, action: PayloadAction<string | null>) {
      state.tempLinkBuildKaraokeFile = action.payload
    },
    setFileDeleting(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.fileDeleting = action.payload
    },
    setPhonogramStatistics(state: TPhonogramsState, action: PayloadAction<TPhonogramStatistics | null>) {
      state.phonogramStatistics = action.payload
    },
    setGenreSortParams(state: TPhonogramsState, action: PayloadAction<TFilterByGenresValue>) {
      state.genreSortParams = action.payload
    },
    setSearchParams(state: TPhonogramsState, action: PayloadAction<string>) {
      state.searchParams = action.payload
      state.orderBy = null
      state.orderByColumn = null
    },
    setDeletedPhonogramsFilterValue(state: TPhonogramsState, action: PayloadAction<TFilterValue>) {
      state.deletedPhonogramsFilterValue = action.payload
    },
    setApprovedFilterValue(state: TPhonogramsState, action: PayloadAction<TFilterValue>) {
      state.approvedFilterValue = action.payload
    },
    setHasMusicFileFilterValue(state: TPhonogramsState, action: PayloadAction<TFilterValue>) {
      state.hasMusicFileFilterValue = action.payload
    },
    setHasLyricsFileFilterValue(state: TPhonogramsState, action: PayloadAction<TFilterValue>) {
      state.hasLyricsFileFilterValue = action.payload
    },
    setDraftFilterValue(state: TPhonogramsState, action: PayloadAction<TFilterValue>) {
      state.draftFilterValue = action.payload
    },
    setVocalsMelodyFilterValue(state: TPhonogramsState, action: PayloadAction<TVocalsMelodyFilterValue>) {
      state.vocalsMelodyFilterValue = action.payload
    },
    setOrderByColumn(state: TPhonogramsState, action: PayloadAction<string>) {
      state.orderByColumn = action.payload
    },
    setOrderBy(state: TPhonogramsState, action: PayloadAction<ESort>) {
      state.orderBy = action.payload
    },
    setRunFilters(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.runFilters = action.payload
    },

    setPhonogramsListForPlaylists(state: TPhonogramsState, action: PayloadAction<Array<TPhonogram>>) {
      state.phonogramsListForPlaylists = action.payload
    },
    setPhonogramsListOnScrollForPlaylists(state: TPhonogramsState, action: PayloadAction<Array<TPhonogram>>) {
      state.phonogramsListForPlaylists = [...state.phonogramsListForPlaylists, ...action.payload]
    },
    setPhonogramsLoadOffsetForPlaylists(state: TPhonogramsState, action: PayloadAction<number>) {
      state.phonogramsLoadOffsetForPlaylists = action.payload
    },
    setPhonogramsListHasMoreForPlaylists(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.phonogramsListHasMoreForPlaylists = action.payload
    },

    pushUnsavedContainerSets(state: TPhonogramsState, action: PayloadAction<ContainerSet>) {
      state.unsavedContainerSets.push(action.payload)
    },
    setSelectedContainerSet(state: TPhonogramsState, action: PayloadAction<ContainerSet | null>) {
      state.selectedContainerSet = action.payload
    },
    updateContainerSetInContainerSets(state: TPhonogramsState, action: PayloadAction<ContainerSet>) {
      if (state.phonogramByID?.containerSets) {
        state.phonogramByID.containerSets = state.phonogramByID?.containerSets.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload
          }
          return item
        })
      }
    },

    updateContainerSetInUnsavedContainerSets(state: TPhonogramsState, action: PayloadAction<ContainerSet>) {
      state.unsavedContainerSets = state.unsavedContainerSets.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        }
        return item
      })
    },

    removeContainerSetFromContainerSets(state: TPhonogramsState, action: PayloadAction<string>) {
      if (state.phonogramByID?.containerSets) {
        state.phonogramByID.containerSets = state.phonogramByID?.containerSets.filter(
          (item) => item.id !== action.payload,
        )
      }
    },

    removeContainerSetFromUnsavedContainerSets(state: TPhonogramsState, action: PayloadAction<string>) {
      state.unsavedContainerSets = state.unsavedContainerSets.filter((item) => item.id !== action.payload)
    },

    clearUnsavedContainerSets(state: TPhonogramsState) {
      state.unsavedContainerSets = []
    },

    setExportCatalogColumns(state: TPhonogramsState, action: PayloadAction<TExportCatalogColumn[]>) {
      state.exportCatalogColumns = action.payload
    },

    setSelectExportCatalogColumn(state: TPhonogramsState, action: PayloadAction<string>) {
      state.exportCatalogColumns = state.exportCatalogColumns.map((item) => {
        if (item.en === action.payload) {
          item.isSelected = !item.isSelected
          return item
        }
        return item
      })
    },

    setSelectallExportCatalogColumn(state: TPhonogramsState, action: PayloadAction<boolean>) {
      state.exportCatalogColumns = state.exportCatalogColumns.map((item) => {
        item.isSelected = action.payload
        return item
      })
    },

    setPhomogramsColumns(state: TPhonogramsState, action: PayloadAction<TPhonogramsColumnsSave>) {
      const sortedBase = action.payload.base.sort((a, b) => {
        const ruleA = tableTitles.find((item) => item.id === a.id)
        const ruleB = tableTitles.find((item) => item.id === b.id)

        if (ruleA && ruleB) {
          return ruleA.rule - ruleB.rule
        }

        return 0
      })

      const sortedColumnsByRule: TPhonogramsColumnsSave = {
        base: sortedBase,
        additional: action.payload.additional,
      }

      state.phonogramsColumns = sortedColumnsByRule
    },
    setQuantityInfo(state: TPhonogramsState, action: PayloadAction<TQuantityInfo>) {
      state.quantityInfo = action.payload
    },
  },
})

export const {
  setPhonogramsList,
  setPhonogramsListOnScroll,
  setPhonogramsLoadOffset,
  setPhonogramsListHasMore,
  setPhonogramByID,
  setPhonogramID,
  setPhonogramByIDIsLoad,
  setEditPhonogramByIDSuccess,
  setCreatePhonogramSuccess,
  setShowValidateFileNameWindow,
  setNextCatalogPhonogramNumber,
  setGenreList,
  setNavbarWidth,
  setBuildingKaraokeFile,
  setKaraokeFileIsBuild,
  setBuildKaraokeFileId,
  setBuildKaraokeFileName,
  setTempLinkBuildKaraokeFile,
  setFileDeleting,
  setPhonogramStatistics,
  setGenreSortParams,
  setSearchParams,
  setDeletedPhonogramsFilterValue,
  setApprovedFilterValue,
  setHasMusicFileFilterValue,
  setHasLyricsFileFilterValue,
  setDraftFilterValue,
  setVocalsMelodyFilterValue,
  setOrderByColumn,
  setOrderBy,
  setRunFilters,
  setFirstUpdate,
  setRowIndex,
  replacePhonogramInPhonogramList,

  setPhonogramsListForPlaylists,
  setPhonogramsListOnScrollForPlaylists,
  setPhonogramsLoadOffsetForPlaylists,
  setPhonogramsListHasMoreForPlaylists,

  setSelectedContainerSet,
  pushUnsavedContainerSets,
  clearUnsavedContainerSets,
  updateContainerSetInContainerSets,
  updateContainerSetInUnsavedContainerSets,
  removeContainerSetFromContainerSets,
  removeContainerSetFromUnsavedContainerSets,

  setExportCatalogColumns,
  setSelectExportCatalogColumn,
  setSelectallExportCatalogColumn,
  setPhomogramsColumns,
  setQuantityInfo,
} = userSlice.actions

export default userSlice.reducer
