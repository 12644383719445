import React from 'react'
import { css } from '@emotion/core'
import ClipLoader from 'react-spinners/ClipLoader'
import { colors } from 'styles/colors'
import styled from 'styled-components'

const overrideSpinnerStyles = css`
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 30px;
  right: 0;
  z-index: 999;
`

type TProps = {
  showSpinner: boolean
  size?: number
}

export const Loader = (props: TProps) => (
  <Wrapper>
    <ClipLoader
      css={overrideSpinnerStyles}
      size={props.size || 100}
      color={colors.blueMain}
      loading={props.showSpinner}
    />
  </Wrapper>
)

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  //backdrop-filter: blur(5px);
`
