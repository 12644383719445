import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RoyaltiesState, Royalty } from 'store/modules/royalties/types'

const initialState: RoyaltiesState = {
  royaltyShowPopUp: false,
  royalties: [],
  sortedRoyalties: [],
  royalty: null,
}

const royaltiesSlice = createSlice({
  name: 'royalties',
  initialState,
  reducers: {
    setRoyaltiesShowPopUp(state: RoyaltiesState, action: PayloadAction<boolean>) {
      state.royaltyShowPopUp = action.payload
    },
    setRoyalties(state: RoyaltiesState, action: PayloadAction<Royalty[]>) {
      state.royalties = action.payload
    },
    setSortedRoyalties(state: RoyaltiesState, action: PayloadAction<Royalty[]>) {
      state.sortedRoyalties = action.payload
    },
    setRoyalty(state: RoyaltiesState, action: PayloadAction<Royalty | null>) {
      state.royalty = action.payload
    },
  },
})

export const { setRoyaltiesShowPopUp, setRoyalties, setRoyalty, setSortedRoyalties } = royaltiesSlice.actions

export default royaltiesSlice.reducer
