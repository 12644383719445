import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AdditionalCode, AdiitionalCodesState } from './types'

const initialState: AdiitionalCodesState = {
  codes: [],
}

const additionalCodesSlice = createSlice({
  name: 'additionalCodes',
  initialState,
  reducers: {
    setCodes(state: AdiitionalCodesState, action: PayloadAction<AdditionalCode[]>) {
      state.codes = action.payload
    },
  },
})

export const { setCodes } = additionalCodesSlice.actions

export default additionalCodesSlice.reducer
