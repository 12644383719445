export enum Routes {
  home = '/',
  auth = '/auth',
  playlists = '/playlists',
  charts = '/charts',
  clients = '/clients',
  statistics = '/statistics',
  usersAndRoles = '/users-and-roles',
  restorePassword = '/restore-password',
  phonogram = '/phonogram',
  playlist = '/playlists/playlist',
  client = '/clients/client',
  user = '/users-and-roles/user',
  blacklist = '/playlists/blacklist',
  entertainmentCenter = '/entertainment-center',
  error = '/error',
  tariffs = '/tariffs',
  tariff = '/tariff',
  app = '/app',
  images = '/images',
  faq = '/faq',
  singers = '/singers',
  singer = '/singer',
  documents = '/documents',
  logs = '/logs',
  noMatch = '*',
}
