import { TState } from 'store/store'

export const getPhonogramsList = (state: TState) => state.phonograms.phonogramsList
export const getPhonogramsHasMore = (state: TState) => state.phonograms.phonogramsListHasMore
export const getPhonogramsListForPlaylists = (state: TState) => state.phonograms.phonogramsListForPlaylists
export const getPhonogramsHasMoreForPlaylists = (state: TState) => state.phonograms.phonogramsListHasMoreForPlaylists
export const getPhonogramID = (state: TState) => state.phonograms.phonogramID
export const getPhonogram = (state: TState) => state.phonograms.phonogramByID
export const getPhonogramByIDIsLoad = (state: TState) => state.phonograms.phonogramByIDIsLoad
export const getEditPhonogramByIDSuccess = (state: TState) => state.phonograms.editPhonogramByIDSuccess
export const getCreatePhonogramSuccess = (state: TState) => state.phonograms.createPhonogramSuccess
export const getPhonogramsLoadOffset = (state: TState) => state.phonograms.phonogramsLoadOffset
export const getPhonogramsLoadOffsetForPlaylists = (state: TState) => state.phonograms.phonogramsLoadOffsetForPlaylists
export const getShowValidateFileNameWindow = (state: TState) => state.phonograms.showValidateFileNameWindow
export const getNextCatalogPhonogramNumber = (state: TState) => state.phonograms.nextCatalogPhonogramNumber
export const getGenresList = (state: TState) => state.phonograms.genresList
export const getNavbarWidth = (state: TState) => state.phonograms.navbarWidth
export const getBuildingKaraokeFile = (state: TState) => state.phonograms.buildingKaraokeFile
export const getKaraokeFileIsBuild = (state: TState) => state.phonograms.karaokeFileIsBuild
export const getBuildKaraokeFileId = (state: TState) => state.phonograms.buildingKaraokeFileId
export const getBuildKaraokeFileName = (state: TState) => state.phonograms.buildingKaraokeFileName
export const getTempLinkBuildKaraokeFile = (state: TState) => state.phonograms.tempLinkBuildKaraokeFile
export const getFileDeleting = (state: TState) => state.phonograms.fileDeleting
export const getPhonogramStatistics = (state: TState) => state.phonograms.phonogramStatistics
export const getGenreSortParams = (state: TState) => state.phonograms.genreSortParams
export const getSearchParams = (state: TState) => state.phonograms.searchParams
export const getDeletedPhonogramsFilterValue = (state: TState) => state.phonograms.deletedPhonogramsFilterValue
export const getApprovedFilterValue = (state: TState) => state.phonograms.approvedFilterValue
export const getHasMusicFileFilterValue = (state: TState) => state.phonograms.hasMusicFileFilterValue
export const getHasLyricsFileFilterValue = (state: TState) => state.phonograms.hasLyricsFileFilterValue
export const getDraftFilterValue = (state: TState) => state.phonograms.draftFilterValue
export const getVocalsMelodyFilterValue = (state: TState) => state.phonograms.vocalsMelodyFilterValue
export const getRunFilters = (state: TState) => state.phonograms.runFilters
export const getOrderByColumn = (state: TState) => state.phonograms.orderByColumn
export const getOrderBy = (state: TState) => state.phonograms.orderBy

export const getFirstUpdate = (state: TState) => state.phonograms.firstUpdate
export const getRowIndex = (state: TState) => state.phonograms.rowIndex

export const getPhonogramsColumns = (state: TState) => state.phonograms.phonogramsColumns
export const getUnsavedContaonerSets = (state: TState) => state.phonograms.unsavedContainerSets
export const getSelectedContainerSet = (state: TState) => state.phonograms.selectedContainerSet
export const getExportCatalogColumns = (state: TState) => state.phonograms.exportCatalogColumns
export const getQuantityInfoPhonograms = (state: TState) => state.phonograms.quantityInfo
