import { request } from 'api'
import { setShowMessageRP, setUser } from 'store/modules/auth/reducer'
import { TAuthInputs } from 'pages/auth'
import { TRestorePasswordInput } from 'pages/restorePassword'
import { AppDispatch } from 'store/store'

export const authorization = (data: TAuthInputs, redirectToHome: () => void) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.post('/users/signin/email', {
        ...data,
        type: 'oks',
      })
      localStorage.setItem('token', response.data.data.token)
      dispatch(getUserInfo())
      redirectToHome()
    } catch (error) {
      console.log(error)
    }
  }
}

export const restorePassword = (data: TRestorePasswordInput, redirectToAuth: () => void) => {
  return async (dispatch: AppDispatch) => {
    try {
      await request.post('/users/password/restore', {
        ...data,
      })
      dispatch(setShowMessageRP(true))
      setTimeout(() => {
        dispatch(setShowMessageRP(false))
        redirectToAuth()
      }, 3000)
    } catch (error) {
      console.log(error)
    }
  }
}

export const deactivateJWT = () => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setUser(null))
      await request.post('/users/logout')
      localStorage.removeItem('token')
    } catch (error) {
      console.log(error)
    }
  }
}

export const getUserInfo = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get('/users/oks/me')
      dispatch(setUser(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }
}
