import { request } from 'api'
import {
  setEditPhonogramByIDSuccess,
  setPhonogramByID,
  setPhonogramByIDIsLoad,
  setPhonogramsList,
  setCreatePhonogramSuccess,
  setPhonogramsListHasMore,
  setPhonogramsListOnScroll,
  setNextCatalogPhonogramNumber,
  setGenreList,
  setBuildingKaraokeFile,
  setBuildKaraokeFileId,
  setTempLinkBuildKaraokeFile,
  setKaraokeFileIsBuild,
  setFileDeleting,
  setPhonogramStatistics,
  replacePhonogramInPhonogramList,
  setPhonogramsListForPlaylists,
  setPhonogramsListOnScrollForPlaylists,
  setPhonogramsListHasMoreForPlaylists,
  pushUnsavedContainerSets,
  clearUnsavedContainerSets,
  setSelectedContainerSet,
  updateContainerSetInContainerSets,
  removeContainerSetFromUnsavedContainerSets,
  updateContainerSetInUnsavedContainerSets,
  setBuildKaraokeFileName,
  setExportCatalogColumns,
  setPhomogramsColumns,
  setQuantityInfo,
} from 'store/modules/phonogramms/reducer'
import { AppDispatch } from 'store/store'
import {
  CreateContainerSetReq,
  TChangeActiveContainerSet,
  TCreateContainerSet,
  TExportCatalogReq,
  TPhonogram,
  TPhonogramsColumnsSave,
  TUpdateContainerSet,
  TUpdateContainerSetReq,
} from 'store/modules/phonogramms/types'
//import { setFileUpLoading } from 'store/modules/file/reducer'
import { TExportCatalogColumn } from './types'

export const getPhonograms = (
  forPlaylists: boolean,
  limit: number,
  isDeleted: boolean | undefined,
  isChecked: boolean | undefined,
  hasMusicFile: boolean | undefined,
  hasLyricsFile: boolean | undefined,
  isDraft: boolean | undefined,
  vocalsMelody: boolean | undefined | 'null',
  genreId: string | undefined,
  searchStr: string,
  orderBy: string | null,
  orderByColumn: string | null,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get(`/phonograms`, {
        params: {
          isDeleted: isDeleted,
          isChecked: isChecked,
          hasMusicFile: hasMusicFile,
          hasMusicLyrics: hasLyricsFile,
          isDraft: isDraft,
          vocalsMelody: vocalsMelody,
          orderBy: orderBy,
          limit: limit,
          genreId: genreId,
          searchStr: searchStr,
          orderByColumn: orderByColumn,
        },
      })
      forPlaylists
        ? dispatch(setPhonogramsListForPlaylists(response.data.data))
        : dispatch(setPhonogramsList(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }
}
export const getPhonogramsListOnScroll = (
  forPlaylists: boolean,
  limit: number,
  offset: number,
  isDeleted: boolean | undefined,
  isChecked: boolean | undefined,
  hasMusicFile: boolean | undefined,
  hasLyricsFile: boolean | undefined,
  isDraft: boolean | undefined,
  vocalsMelody: boolean | undefined | 'null',
  genreId: string | undefined,
  searchStr: string,
  orderBy: string | null,
  orderByColumn: string | null,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get(`/phonograms`, {
        params: {
          isDeleted: isDeleted,
          isChecked: isChecked,
          hasMusicFile: hasMusicFile,
          hasMusicLyrics: hasLyricsFile,
          isDraft: isDraft,
          vocalsMelody: vocalsMelody,
          orderBy: orderBy,
          limit: limit,
          offset: offset,
          genreId: genreId,
          searchStr: searchStr,
          orderByColumn: orderByColumn,
        },
      })
      const phonogramsList = response.data.data
      forPlaylists
        ? dispatch(setPhonogramsListOnScrollForPlaylists(phonogramsList))
        : dispatch(setPhonogramsListOnScroll(phonogramsList))

      if (phonogramsList.length < limit) {
        forPlaylists ? dispatch(setPhonogramsListHasMoreForPlaylists(false)) : dispatch(setPhonogramsListHasMore(false))
      }
    } catch (error) {
      console.log(error)

      forPlaylists ? dispatch(setPhonogramsListHasMoreForPlaylists(false)) : dispatch(setPhonogramsListHasMore(false))
    }
  }
}

export const getPhonogramByID = (id: string | null, withDeleted?: boolean) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setPhonogramByIDIsLoad(false))
    try {
      const response = await request.get(`/phonograms/${id}`, {
        params: {
          withDeleted,
        },
      })
      const phonogram = response.data.data
      dispatch(setPhonogramByID(phonogram))
      dispatch(setPhonogramByIDIsLoad(true))
    } catch (error) {
      console.log(error)

      dispatch(setPhonogramByIDIsLoad(false))
    }
  }
}

export const getNextCatalogNumber = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get(`/phonograms/next-catalog-phonogram-number`)
      dispatch(setNextCatalogPhonogramNumber(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getMusicFileLinkFromServer = async (id: string) => {
  try {
    const response = await request.get(`/files/${id}/link`)
    return response.data.data
  } catch (error) {
    console.log(error)
  }
}

export const deletePhonogramByID = (id: string | null) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.delete(`/phonograms/${id}`)
      if (response.data.success) {
        dispatch(setPhonogramByID(response.data.data))
        dispatch(replacePhonogramInPhonogramList(response.data.data))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const restorePhonogramByID = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.put(`/phonograms/${id}/restore`)
      dispatch(setPhonogramByID(response.data.data))
      dispatch(replacePhonogramInPhonogramList(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getExportCatalogColumnsFromServer = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get(`/files/phonogram-catalog/export/headers`)
      dispatch(setExportCatalogColumns(response.data.data.exportSettings))
    } catch (error) {
      console.log(error)
    }
  }
}

export const saveExportCatalogColumnsFromServer = (data: TExportCatalogColumn[]) => {
  return async (dispatch: AppDispatch) => {
    try {
      await request.put(`/files/phonogram-catalog/export/headers`, { exportSettings: data })
    } catch (error) {
      console.log(error)
    }
  }
}

export const exportCatalogXls = (data: TExportCatalogReq, onSuccess?: () => void) => {
  return async () => {
    try {
      const response = await request.post(`/files/phonogram-catalog/export`, data, { responseType: 'blob' })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `spelly_catalog_${new Date().toLocaleDateString('ru-RU')}.xlsx`)
      document.body.appendChild(link)
      onSuccess && onSuccess()
      link.click()
    } catch (error) {
      console.log(error)
    }
  }
}

export const createEditPhonogramByID = (
  id: string | null,
  initialData: TPhonogram,
  createOrEdit: string,
  // buildKaraokeFileId: string | null,
) => {
  const coverImgFormData = new FormData()
  if (initialData.imgFile && initialData.imgFile.length !== 0) {
    coverImgFormData.append('file', initialData.imgFile[0])
  }
  console.log('initialData', initialData)

  const newDataWithoutFiles = { ...initialData }
  delete newDataWithoutFiles.musicLyrics
  delete newDataWithoutFiles.imgFile

  if (createOrEdit === 'edit') {
    return async (dispatch: AppDispatch) => {
      try {
        if (initialData.imgFile && initialData.imgFile.length !== 0) {
          const uploadCoverImgFileResponse = await request.post(`/files`, coverImgFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          newDataWithoutFiles.coverImageId = uploadCoverImgFileResponse.data.data.id
        }

        const response = await request.put(`/phonograms/${id}`, { ...newDataWithoutFiles })
        if (response.data.success) {
          dispatch(replacePhonogramInPhonogramList(response.data.data))
          dispatch(setEditPhonogramByIDSuccess(true))
          dispatch(clearUnsavedContainerSets())
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  if (createOrEdit === 'create') {
    return async (dispatch: AppDispatch) => {
      try {
        if (initialData.imgFile && initialData.imgFile.length !== 0) {
          const uploadCoverImgFileResponse = await request.post(`/files`, coverImgFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          newDataWithoutFiles.coverImageId = uploadCoverImgFileResponse.data.data.id
        }

        const response = await request.post('/phonograms', {
          ...newDataWithoutFiles,
        })
        if (response.data.success) {
          console.log(response.data.data)
          dispatch(setPhonogramsListOnScroll([response.data.data]))
          dispatch(setCreatePhonogramSuccess(true))
          dispatch(clearUnsavedContainerSets())
        }
      } catch (error) {
        console.log(error)
        dispatch(setCreatePhonogramSuccess(false))
      }
    }
  }
}

export const changeActiveContainerSet = (phonogrammId: string, data: TChangeActiveContainerSet) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.put(`/phonograms/${phonogrammId}`, data)
      dispatch(setPhonogramByID(response.data.data))
      dispatch(replacePhonogramInPhonogramList(response.data.data))
    } catch (e) {
      console.log(e)
    }
  }
}

export const createContainerSet = (data: TCreateContainerSet, onSuccess?: () => void) => {
  return async (dispatch: AppDispatch) => {
    const createContainerSetReq: CreateContainerSetReq = {
      catalogPhonogramNumber: data.catalogPhonogramNumber!,
      isExplicit: data.isExplicit,
      vocalsMelody: data.vocalsMelody,
      comment: data.comment,
      musicFileId: null,
      musicLyricsId: null,
    }

    try {
      //не должно быть что сущесвует краоке файл и id собранного файла. Только что то одно либо ничего
      //если загружен караоке файл то загружаем его на сервер и получаем id загруженного файла
      if (data.karaokeFile) {
        const uploadData = new FormData()
        uploadData.append('file', data.karaokeFile)

        const res = await request.post(`/music-file`, uploadData, {
          params: {
            catalogPhonogramNumber: data.catalogPhonogramNumber,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        createContainerSetReq.musicFileId = res.data.data.id
      }
      //если есть id собранного файла то для создания набора используем его
      if (data.buildKaraokeFileId) {
        createContainerSetReq.musicFileId = data.buildKaraokeFileId
      }

      if (data.titlesFile) {
        const uploadData = new FormData()
        uploadData.append('file', data.titlesFile)

        const res = await request.post(`/music-lyrics`, uploadData, {
          params: {
            catalogPhonogramNumber: data.catalogPhonogramNumber,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        createContainerSetReq.musicLyricsId = res.data.data.id
      }

      //создаем набор, важно что набор не прицепляется к фонограмме, нужно при
      //сохранении фонограммы передать массив наборов, чтобы они прицепились к фонограмме
      const response = await request.post('/containers', createContainerSetReq)

      dispatch(pushUnsavedContainerSets(response.data.data))

      onSuccess && onSuccess()
    } catch (e) {
      console.log(e)
    }
  }
}

export const updateContainerSet = (data: TUpdateContainerSet, containerSetId: string, onSuccess?: () => void) => {
  return async (dispatch: AppDispatch) => {
    const updateContainerSetReq: TUpdateContainerSetReq = {
      isExplicit: data.isExplicit,
      vocalsMelody: data.vocalsMelody,
      comment: data.comment,
      musicFileId: data.oldKaraokeFileId,
      musicLyricsId: data.oldTitlesFileId,
    }

    try {
      //не должно быть что сущесвует краоке файл и id собранного файла. Только что то одно либо ничего
      //если загружен караоке файл то загружаем его на сервер и получаем id загруженного файла
      if (data.karaokeFile) {
        const uploadData = new FormData()
        uploadData.append('file', data.karaokeFile)

        const res = await request.post(`/music-file`, uploadData, {
          params: {
            catalogPhonogramNumber: data.catalogPhonogramNumber,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        updateContainerSetReq.musicFileId = res.data.data.id
      }
      //если есть id собранного файла то для создания набора используем его
      if (data.buildKaraokeFileId) {
        updateContainerSetReq.musicFileId = data.buildKaraokeFileId
      }

      if (data.titlesFile) {
        const uploadData = new FormData()
        uploadData.append('file', data.titlesFile)

        const res = await request.post(`/music-lyrics`, uploadData, {
          params: {
            catalogPhonogramNumber: data.catalogPhonogramNumber,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        updateContainerSetReq.musicLyricsId = res.data.data.id
      }

      //создаем набор, важно что набор не прицепляется к фонограмме, нужно при
      //сохранении фонограммы передать массив наборов, чтобы они прицепились к фонограмме
      const response = await request.put(`/containers/${containerSetId}`, updateContainerSetReq)
      dispatch(updateContainerSetInContainerSets(response.data.data))
      dispatch(updateContainerSetInUnsavedContainerSets(response.data.data))

      onSuccess && onSuccess()
    } catch (e) {
      console.log(e)
    }
  }
}

export const addAdditionalCodeToContainerSet = (
  data: { typeId: string; code: number },
  containerSetId: string,
  phonogramID: string,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await request.post(`/containers/${containerSetId}/additional-codes`, data)

      const response = await request.get(`/containers/${containerSetId}`)
      dispatch(setSelectedContainerSet(response.data.data))
      dispatch(getPhonogramByID(phonogramID, true) as any)
    } catch (e) {
      console.log(e)
    }
  }
}

export const deleteAdditionalCodeFromContainerSet = (containerSetId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await request.delete(`/containers/${containerSetId}/additional-codes`)
      const response = await request.get(`/containers/${containerSetId}`)
      dispatch(setSelectedContainerSet(response.data.data))
      dispatch(updateContainerSetInContainerSets(response.data.data))
    } catch (e) {
      console.log(e)
    }
  }
}

export const deleteContainerSet = (containerSetId: string, onSuccess: () => void, phonogrammId: string | null) => {
  return async (dispatch: AppDispatch) => {
    try {
      await request.delete(`/containers/${containerSetId}`)
      //dispatch(removeContainerSetFromContainerSets(containerSetId))
      dispatch(removeContainerSetFromUnsavedContainerSets(containerSetId))

      if (phonogrammId !== null) {
        const response = await request.get(`/phonograms/${phonogrammId}`, {
          params: {
            withDeleted: true,
          },
        })

        dispatch(setPhonogramByID(response.data.data))
        dispatch(replacePhonogramInPhonogramList(response.data.data))
      }

      onSuccess && onSuccess()
    } catch (e) {
      console.log(e)
    }
  }
}

export const deleteKaraokeFileFromContainerSet = (
  karaokeFileId: string,
  containerSetId: string,
  phonogrammId: string,
  isActiveContainerSet: boolean,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      //обнуляем караоке файл в контейнер сете
      const response = await request.put(`/containers/${containerSetId}`, { musicFileId: null })

      dispatch(setSelectedContainerSet(response.data.data))
      dispatch(updateContainerSetInUnsavedContainerSets(response.data.data))

      if (isActiveContainerSet) {
        const resPhonogramm = await request.put(`/phonograms/${phonogrammId}`, {
          isDraft: true,
          activeContainerSetId: null,
        })
        dispatch(setPhonogramByID(resPhonogramm.data.data))
        dispatch(replacePhonogramInPhonogramList(resPhonogramm.data.data))
      } else {
        dispatch(updateContainerSetInContainerSets(response.data.data))
      }

      //удаляем караоке файл
      await request.delete(`/music-file/${karaokeFileId}`)
    } catch (e) {
      console.log(e)
    }
  }
}

export const deleteTitlesFileFromContainerSet = (
  titlesFileId: string,
  containerSetId: string,
  phonogrammId: string,
  isActiveContainerSet: boolean,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      //обнуляем караоке файл в контейнер сете
      const response = await request.put(`/containers/${containerSetId}`, { musicLyricsId: null })
      dispatch(setSelectedContainerSet(response.data.data))
      dispatch(updateContainerSetInUnsavedContainerSets(response.data.data))

      if (isActiveContainerSet) {
        const resPhonogramm = await request.put(`/phonograms/${phonogrammId}`, {
          isDraft: true,
          activeContainerSetId: null,
        })
        dispatch(setPhonogramByID(resPhonogramm.data.data))
        dispatch(replacePhonogramInPhonogramList(resPhonogramm.data.data))
      } else {
        dispatch(updateContainerSetInContainerSets(response.data.data))
      }

      //удаляем титры
      await request.delete(`/music-lyrics/${titlesFileId}`)
    } catch (e) {
      console.log(e)
    }
  }
}

export const deleteKaraokeFileFromServer = (karaokeFileId: string) => {
  return async () => {
    try {
      await request.delete(`/music-file/${karaokeFileId}`)
    } catch (e) {
      console.log(e)
    }
  }
}

export const getGenresListFromServer = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get(`/reference/genres`)
      dispatch(setGenreList(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const buildingKaraokeFile = (data: FormData, catalogPhonogramNumber: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setBuildingKaraokeFile(true))
    try {
      const response = await request.post(`/music-file/building?catalogPhonogramNumber=${catalogPhonogramNumber}`, data)
      dispatch(setBuildKaraokeFileId(response.data.data.id))
      dispatch(setBuildKaraokeFileName(response.data.data.file.originalName))
      dispatch(setTempLinkBuildKaraokeFile(response.data.data.link))
      dispatch(setKaraokeFileIsBuild(true))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(setBuildingKaraokeFile(false))
    }
  }
}

export const deleteMusicLyricsByID = (id: string, phonogramID: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setFileDeleting(true))
    try {
      await request.delete(`/music-lyrics/${id}`)
      dispatch(getPhonogramByID(phonogramID, false) as any)
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(setFileDeleting(false))
    }
  }
}

export const deleteMusicFileByID = (id: string, phonogramID: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setFileDeleting(true))
    try {
      await request.delete(`/music-file/${id}`)
      dispatch(getPhonogramByID(phonogramID, false) as any)
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(setFileDeleting(false))
    }
  }
}

export const getPhonogramStatisticsByID = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get(`/statistics/${id}`)
      const stat = response.data.data
      dispatch(setPhonogramStatistics(stat))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getPhonogramColumnSettings = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get('/users/oks/me/settings')
      dispatch(setPhomogramsColumns(response.data.data.phonogramSettings))
    } catch (error) {
      console.log(error)
    }
  }
}

export const savePhonogramsColumns = (data: TPhonogramsColumnsSave) => {
  return async (dispatch: AppDispatch) => {
    try {
      await request.put('/users/oks/me/settings', { phonogramSettings: data })
      dispatch(setPhomogramsColumns(data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const startLoadContainers = (onSuccess?: () => void) => {
  return async () => {
    try {
      await request.post(`/containers/imports`)
      onSuccess?.()
    } catch (error) {
      console.log(error)
    }
  }
}

export const getQuantityInfoForPhonograms = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get(`/phonograms/quantity-info`)
      dispatch(setQuantityInfo(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }
}
