import React, { FC } from 'react'
import { colors } from 'styles/colors'
import { useDispatch, useSelector } from 'react-redux'
import hideArrowIcon from 'assets/icons/hideArrow.svg'
import { getNavbarWidth } from 'store/modules/phonogramms/selectors'
import { setNavbarWidth } from 'store/modules/phonogramms/reducer'
import { Profile } from 'pages/main/sideBar/profile'
import { Navigation } from 'pages/main/sideBar/navigation'
import styled, { css } from 'styled-components'

export const SideBar: FC = () => {
  const width = useSelector(getNavbarWidth)
  const dispatch = useDispatch()

  const setSideBarWidth = () => {
    if (width === 50) {
      dispatch(setNavbarWidth(240))
    }
    if (width === 240) {
      dispatch(setNavbarWidth(50))
    }
  }

  return (
    <Container $width={width}>
      <Profile />
      <Navigation />
      <Arrow $width={width} onClick={setSideBarWidth} />
    </Container>
  )
}

type TStyledProps = {
  $width: number
}

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: ${(props: TStyledProps) => `${props.$width}px`};
  max-width: ${(props: TStyledProps) => `${props.$width}px`};
  border-right: 1px solid ${colors.blueSecond};
  overflow: hidden;
`
const Arrow = styled.div`
  cursor: pointer;
  width: 12px;
  height: 4px;
  position: absolute;
  z-index: 10;
  top: 500px;
  right: -4px;
  background-image: url(${hideArrowIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${colors.blueSecond};
  ${(props: TStyledProps) =>
    props.$width === 50
      ? css`
          transform: rotate(90deg);
        `
      : css`
          transform: rotate(-90deg);
        `};
  padding: 4px;
`
