import { TSinger } from 'store/modules/singers/types'
import { AdditionalCodePhonogramm } from '../additionalCodes/types'

export type TPhonogramsState = {
  rowIndex: number
  firstUpdate: boolean
  phonogramID: string | null
  phonogramsList: Array<TPhonogram>
  phonogramsListHasMore: boolean
  phonogramsListForPlaylists: Array<TPhonogram>
  phonogramsListHasMoreForPlaylists: boolean
  phonogramByID: TPhonogram | null
  phonogramByIDIsLoad: boolean
  editPhonogramByIDSuccess: boolean
  createPhonogramSuccess: boolean
  phonogramsLoadOffset: number
  phonogramsLoadOffsetForPlaylists: number
  showValidateFileNameWindow: boolean
  nextCatalogPhonogramNumber: number | null
  genresList: TGenreItem[]
  navbarWidth: number
  buildingKaraokeFile: boolean
  buildingKaraokeFileId: string | null
  buildingKaraokeFileName: string | null
  tempLinkBuildKaraokeFile: string | null
  karaokeFileIsBuild: boolean
  fileDeleting: boolean
  phonogramStatistics: TPhonogramStatistics | null
  genreSortParams: TFilterByGenresValue
  searchParams: string
  deletedPhonogramsFilterValue: TFilterValue
  approvedFilterValue: TFilterValue
  hasMusicFileFilterValue: TFilterValue
  hasLyricsFileFilterValue: TFilterValue
  draftFilterValue: TFilterValue
  vocalsMelodyFilterValue: TVocalsMelodyFilterValue
  orderByColumn: string | null
  orderBy: ESort | null
  runFilters: boolean

  selectedContainerSet: ContainerSet | null
  unsavedContainerSets: ContainerSet[]
  exportCatalogColumns: TExportCatalogColumn[]
  phonogramsColumns: TPhonogramsColumnsSave
  quantityInfo: TQuantityInfo | null
}

export type TQuantityInfo = {
  all: number
  active: number
  draft: number
  deleted: number
  blacklist: number
  withoutFiles: number
}

export type TPhonogramsColumnsSave = {
  base: TPhomogramColumnToSave[]
  additional: TPhomogramColumnToSave[]
}

export type TPhomogramColumnToSave = {
  id: string
  name: string
  isChecked: boolean
}

export type TSelectIsExplicit = {
  label: string
  value: boolean
}

export type TSelectVocalsMelody = {
  label: string
  value: boolean | null
}

export type TFile = {
  createdAt: string
  id: number
  uploadedBy: number
  relativePath: string
  mimeType: string
  size: number
  originalName: string
  updatedAt: string
  deletedAt: string | null
}

export type TMusicFile = {
  createdAt: string
  updatedAt: string
  deletedAt: string
  id: string
  catalogPhonogramNumber: number
  file: TFile
  streamOrder: string
}

export type TLyricsFile = {
  createdAt: string
  updatedAt: string
  deletedAt: string
  id: string
  lyrics: string
  file: TFile
}

export type TPhonogram = {
  id?: string | null
  isrc: string
  catalogPhonogramNumber?: number
  genre?: TGenreItem | null
  genreId?: string
  name: string
  nameTr: string
  copyright: string
  copyrightTr: string
  singerName: string
  singerTrName: string
  album: string
  albumTr: string
  authorName: string
  authorTrName: string
  authorTextName: string
  authorTextTrName: string
  isExplicit: boolean | TSelectIsExplicit
  isDraft: boolean
  isApproved: boolean
  isBackground: boolean
  musicFile?: TMusicFile
  musicFileId?: string
  musicLyrics?: TLyricsFile
  musicLyricsId?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //uploadKaraokeFile?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //uploadSubTitlesFile?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imgFile?: any
  createdAt?: string
  deletedAt?: string | null
  updatedAt?: string
  numberOfListening?: number
  phonogramId?: string
  coverImageId?: null | string
  coverImage?: {
    id: string
    relativePath: string
  } | null
  inBlacklist?: boolean
  listeningCount?: number
  singerIds?: string[]
  singers?: TSinger[]
  comment: string | null
  vocalsMelody: boolean | 'null' | undefined | null
  additionalCodes?: AdditionalCodePhonogramm[]
  containerSets?: ContainerSet[]
  activeContainerSetId?: string | null
  countryTag: string[]
  tariffTag: string[]
}

export type ContainerSet = {
  id: string
  isExplicit: boolean
  vocalsMelody: boolean | null
  comment: string
  musicFile: TMusicFile
  musicLyrics: TLyricsFile
  additionalCode: AdditionalCodePhonogramm
  isActive: boolean
}

export type TGenreItem = {
  id: string | undefined
  name: string
  nameTr: string
  value?: string
  label?: string
}

export type TFilterValue = {
  value: boolean | undefined
  label: string
}

export type TVocalsMelodyFilterValue = {
  value: boolean | undefined | 'null'
  label: string
}

export type TFilterByGenresValue = {
  value: string | undefined
  label: string
}

export type TPhonogramStatistics = {
  all: number
  daily: number
  monthly: number
  yearly: number
}

export enum ESort {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum tableIds {
  catalogPhonogramNumber = 'catalogPhonogramNumber',
  genre = 'genre',
  name = 'name',
  singerName = 'singerName',
  authorName = 'authorName',
  authorTextName = 'authorTextName',
  isApproved = 'isApproved',
  musicFile = 'musicFile',
  musicLyrics = 'musicLyrics',
  isExplicit = 'isExplicit',
  vocalsMelody = 'vocalsMelody',
  updatedAt = 'updatedAt',
}

export type TCreateContainerSet = {
  catalogPhonogramNumber: number | null | undefined
  karaokeFile: File | null
  buildKaraokeFileId: string | null
  titlesFile: File | null
  comment: string
  isExplicit: boolean
  vocalsMelody: boolean | null
}

export type CreateContainerSetReq = {
  catalogPhonogramNumber: number
  isExplicit: boolean
  vocalsMelody: boolean | null
  comment: string
  musicFileId: string | null
  musicLyricsId: string | null
}

export type TUpdateContainerSet = {
  catalogPhonogramNumber: number
  karaokeFile: File | null
  oldKaraokeFileId: string | null | undefined
  buildKaraokeFileId: string | null
  titlesFile: File | null
  oldTitlesFileId: string | null | undefined
  comment: string
  isExplicit: boolean
  vocalsMelody: boolean | null
}

export type TUpdateContainerSetReq = {
  isExplicit: boolean
  vocalsMelody: boolean | null
  comment: string
  musicFileId: string | null | undefined
  musicLyricsId: string | null | undefined
}

export type TChangeActiveContainerSet = {
  activeContainerSetId: string | null
  containerSetIds: string[]
  isDraft?: boolean
}

export type TExportCatalogColumn = {
  ru: string
  en: string
  typeId?: string
  isSelected: boolean
}

export type TExportCatalogReq = {
  en: string[]
  typeIds: string[]
}
