import React, { FC } from 'react'
import logo from 'assets/images/formLogo.svg'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { authorization } from 'store/modules/auth/actions'
import { Container, Form, Logo, Title, Text, Input, StyledLink, Button } from 'components/ui/auth'

export type TAuthInputs = {
  email: string
  password: string
}

const Auth: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { register, handleSubmit } = useForm<TAuthInputs>()
  const redirectToHome = () => history.push(Routes.home)

  const onSubmit = (data: TAuthInputs) => {
    dispatch(authorization(data, redirectToHome))
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Logo src={logo} alt="Логотип ОКС" />
        <Title>Административная система</Title>
        <Text>Авторизация</Text>
        <Input name="email" type="email" placeholder="Email" required={true} ref={register()} />
        <Input name="password" type="password" placeholder="Пароль" required={true} minLength={6} ref={register()} />
        <StyledLink to={Routes.restorePassword}>Восстановить пароль</StyledLink>
        <Button>Войти</Button>
      </Form>
    </Container>
  )
}

export default Auth
