import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from 'App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from 'store/store'
// import { PersistGate } from 'redux-persist/integration/react'
// import { persistStore } from 'redux-persist'

// const persistor = persistStore(store)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/*<PersistGate persistor={persistor} loading={null}>*/}
      <App />
      {/*</PersistGate>*/}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

reportWebVitals()
