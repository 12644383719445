import React, { FC } from 'react'
import { ReactComponent as UserAvatar } from 'assets/icons/userAvatar.svg'
import { ReactComponent as IconLogOut } from 'assets/icons/iconLogOut.svg'
import { deactivateJWT } from 'store/modules/auth/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import { getUser } from 'store/modules/auth/selectors'
import { colors } from 'styles/colors'
import styled from 'styled-components'

export const Profile: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(getUser)

  const logout = () => {
    dispatch(deactivateJWT())
    history.push(Routes.auth)
  }

  return (
    <Container>
      <UserAvatar style={{ minWidth: 32 }} />
      {user && (
        <Wrapper>
          <Name>{user.lastName}</Name>
          <small>Роль: {user.role ? user.role.name : ''}</small>
        </Wrapper>
      )}

      <StyledIconLogOut onClick={logout} />
    </Container>
  )
}

const Container = styled.div`
  height: 80px;
  max-height: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.blueSecond};
  padding: 0 8px;
  margin-bottom: 16px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 10px;
`
const Name = styled.span`
  font-size: 24px;
  color: ${colors.grayTitle};
`
const StyledIconLogOut = styled(IconLogOut)`
  margin-left: auto;
`
