import styled from 'styled-components'
import { colors } from 'styles/colors'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  min-width: 400px;
  min-height: 800px;
  display: grid;
  place-items: center;
  @media (max-width: 1600px) {
    min-width: 380px;
    min-height: 460px;
  }
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${colors.blueMain};
  border-radius: 8px;
  padding: 60px 100px;
  @media (max-width: 1600px) {
    padding: 30px 50px;
  }
`
export const Logo = styled.img`
  width: 100%;
  margin-bottom: 50px;
  @media (max-width: 1600px) {
    width: 280px;
    margin-bottom: 24px;
  }
`
export const Title = styled.h1`
  font-size: 24px;
  text-transform: uppercase;
  color: ${colors.blueMain};
  margin: 0 0 14px 0;
  @media (max-width: 1600px) {
    font-size: 18px;
  }
`
export const Text = styled.span`
  font-weight: 700;
  font-size: 24px;
  color: ${colors.graySecond};
  @media (max-width: 1600px) {
    font-size: 18px;
  }
`
export const Input = styled.input`
  width: 100%;
  text-align: center;
  border: 1px solid ${colors.blueMain};
  border-radius: 8px;
  font-size: 24px;
  padding: 18px 0;
  margin-top: 30px;
  @media (max-width: 1600px) {
    font-size: 18px;
    padding: 9px 0;
    margin-top: 14px;
  }
`
export const StyledLink = styled(Link)`
  color: ${colors.graySecond};
  font-size: 18px;
  margin-top: 24px;
  @media (max-width: 1600px) {
    font-size: 14px;
    margin-top: 8px;
  }
`
export const Button = styled.button`
  width: 100%;
  color: ${colors.wight};
  font-weight: 700;
  font-size: 24px;
  background-color: ${colors.blueMain};
  border-radius: 8px;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 20px 0;
  margin-top: 35px;
  &:hover {
    background-color: ${colors.blueMainHover};
    box-shadow: 0 4px 4px rgba(33, 100, 169, 0.25);
  }
  @media (max-width: 1600px) {
    font-size: 18px;
    padding: 9px 0;
    margin-top: 14px;
  }
`
