import React, { FC, Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Header } from 'pages/main/header'
import { Routes } from 'routes/routes'
import { useSelector } from 'react-redux'
import {
  getKaraokeFilesUploading,
  getShowParsingWindow,
  getXlsFileParsing,
  getFileUploading,
} from 'store/modules/file/selectors'
import styled from 'styled-components'
import { SideBar } from 'pages/main/sideBar'
import { PopUpLoadingFiles } from 'components/popUpLoadingFiles'
import { PopUpParsing } from 'components/popUpParsing'
import { Loader } from 'components/loader/Loder'
import NotFound from 'pages/404'

const Playlist = lazy(() => import('pages/playlist'))
const BlackList = lazy(() => import('pages/blacklist'))
const EntertainmentCenter = lazy(() => import('pages/entertainmentCenter'))
const TariffsAndCoefficients = lazy(() => import('pages/tariffs'))
const Tariff = lazy(() => import('pages/tariff'))
const Phonograms = lazy(() => import('pages/phonograms'))
const Phonogram = lazy(() => import('pages/phonogram'))
const User = lazy(() => import('pages/user'))
const Client = lazy(() => import('pages/client'))
const Playlists = lazy(() => import('pages/playlists'))
const Charts = lazy(() => import('pages/ChartsPage/ChartsPage'))
const Clients = lazy(() => import('pages/clients'))
const Statistics = lazy(() => import('pages/statistics'))
const UsersAndRoles = lazy(() => import('pages/users'))
const Images = lazy(() => import('pages/images'))
const FAQ = lazy(() => import('pages/faq'))
const Documents = lazy(() => import('pages/documents'))
const Logs = lazy(() => import('pages/logs'))
const Singer = lazy(() => import('pages/singer'))
const Singers = lazy(() => import('pages/singers'))

const MainPage: FC = () => {
  const showPopUp = useSelector(getShowParsingWindow)
  const xlsFileLoading = useSelector(getFileUploading)
  const xlsFileParsing = useSelector(getXlsFileParsing)
  const karaokeFileLoading = useSelector(getKaraokeFilesUploading)

  return (
    <Container>
      {showPopUp && <PopUpParsing />}
      {xlsFileLoading && <PopUpLoadingFiles />}
      {xlsFileParsing && <PopUpLoadingFiles />}
      {karaokeFileLoading && <PopUpLoadingFiles />}

      <Header />
      <Main>
        <SideBar />
        <Suspense fallback={<Loader showSpinner={true} />}>
          <Switch>
            <Route exact path={Routes.home} component={Phonograms} />
            <Route exact path={Routes.playlists} component={Playlists} />
            <Route exact path={Routes.charts} component={Charts} />
            <Route exact path={Routes.clients} component={Clients} />
            <Route exact path={Routes.statistics} component={Statistics} />
            <Route exact path={Routes.usersAndRoles} component={UsersAndRoles} />
            <Route exact path={Routes.phonogram} component={Phonogram} />
            <Route exact path={Routes.playlist + '/' + ':playlistId?'} component={Playlist} />
            <Route exact path={Routes.client + '/' + ':clientId?'} component={Client} />
            <Route
              exact
              path={Routes.entertainmentCenter + '/' + ':entertainmentCenterId'}
              component={EntertainmentCenter}
            />
            <Route exact path={Routes.user} component={User} />
            <Route exact path={Routes.blacklist} component={BlackList} />
            <Route exact path={Routes.tariffs} component={TariffsAndCoefficients} />
            <Route exact path={Routes.images} component={Images} />
            <Route exact path={Routes.faq} component={FAQ} />
            <Route exact path={Routes.documents} component={Documents} />
            <Route exact path={Routes.logs} component={Logs} />
            <Route exact path={Routes.tariff + '/' + ':tariffId?'} component={Tariff} />
            <Route exact path={Routes.singers} component={Singers} />
            <Route exact path={Routes.singer + '/' + ':id?'} component={Singer} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Main>
    </Container>
  )
}

export default MainPage

const Container = styled.div`
  height: 100vh;
  max-width: 1920px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 0 auto;
`

const Main = styled.main`
  display: flex;
  height: 100%;
`
