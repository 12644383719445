import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TTariff, TTariffsState } from 'store/modules/tariffs/types'

const initialState: TTariffsState = {
  tariffs: [],
  tariff: null,
}

const userSlice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {
    setTariffs(state: TTariffsState, action: PayloadAction<TTariff[]>) {
      state.tariffs = action.payload
    },
    setTariff(state: TTariffsState, action: PayloadAction<TTariff | null>) {
      state.tariff = action.payload
    },
  },
})

export const { setTariffs, setTariff } = userSlice.actions

export default userSlice.reducer
