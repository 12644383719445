import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TSinger, TSingersState } from 'store/modules/singers/types'

const initialState: TSingersState = {
  singersList: [],
  singer: null,
  singerRowIndex: 0,
  orderByColumn: 'singerName',
  orderBy: 'ASC',
}

const userSlice = createSlice({
  name: 'singers',
  initialState,
  reducers: {
    setSingersList(state: TSingersState, action: PayloadAction<TSinger[]>) {
      state.singersList = action.payload
    },
    setSinger(state: TSingersState, action: PayloadAction<TSinger | null>) {
      state.singer = action.payload
    },
    setSingerRowIndex(state: TSingersState, action: PayloadAction<number>) {
      state.singerRowIndex = action.payload
    },
    setOrderByColumn(state: TSingersState, action: PayloadAction<'rankingOrder' | 'singerName'>) {
      state.orderByColumn = action.payload
    },
    setOrderBy(state: TSingersState, action: PayloadAction<'ASC' | 'DESC'>) {
      state.orderBy = action.payload
    },
  },
})

export const { setSingersList, setSinger, setSingerRowIndex, setOrderByColumn, setOrderBy } = userSlice.actions

export default userSlice.reducer
