import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  TClientInList,
  TClientsState,
  TDevice,
  TClient,
  TPayment,
  TRoom,
  TPlayersUser,
  TApprovedFilterValue,
  TExceptionFilterValue,
  TContract,
  TQuantityInfoClent,
} from 'store/modules/clients/types'

const initialState: TClientsState = {
  clientById: null,
  quantityInfo: null,
  clientsList: [],
  devicesList: [],
  playersUsersList: [],
  clientPaymentHistory: [],
  spotRooms: [],
  clientsFilters: {
    approvedFilter: { value: undefined, label: 'Все' },
    exceptionFilter: { value: undefined, label: 'Все' },
    searchTextFilter: '',
  },
}

const userSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClientById(state: TClientsState, action: PayloadAction<TClient | null>) {
      state.clientById = action.payload
    },
    setClientList(state: TClientsState, action: PayloadAction<Array<TClientInList>>) {
      state.clientsList = action.payload
    },
    setDevicesList(state: TClientsState, action: PayloadAction<Array<TDevice>>) {
      state.devicesList = action.payload
    },
    setPlayersUsersList(state: TClientsState, action: PayloadAction<Array<TPlayersUser>>) {
      state.playersUsersList = action.payload
    },
    setSpotsRoomsList(state: TClientsState, action: PayloadAction<Array<TRoom>>) {
      state.spotRooms = action.payload
    },
    setClientPaymentHistory(state: TClientsState, action: PayloadAction<TPayment[]>) {
      state.clientPaymentHistory = action.payload
    },
    setApprovedFilter(state: TClientsState, action: PayloadAction<TApprovedFilterValue>) {
      state.clientsFilters.approvedFilter = action.payload
    },
    setExceptionFilter(state: TClientsState, action: PayloadAction<TExceptionFilterValue>) {
      state.clientsFilters.exceptionFilter = action.payload
    },
    setSearchTextFilter(state: TClientsState, action: PayloadAction<string>) {
      state.clientsFilters.searchTextFilter = action.payload
    },
    updateContractInfo(state: TClientsState, action: PayloadAction<TContract>) {
      if (state.clientById) {
        state.clientById.contracts = state.clientById.contracts.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload
          }
          return item
        })
      }
    },
    setQuantityInfoClent(state: TClientsState, action: PayloadAction<TQuantityInfoClent | null>) {
      state.quantityInfo = action.payload
    },
  },
})

export const {
  setClientList,
  setClientById,
  setDevicesList,
  setClientPaymentHistory,
  setSpotsRoomsList,
  setPlayersUsersList,
  setApprovedFilter,
  setExceptionFilter,
  setSearchTextFilter,
  updateContractInfo,
  setQuantityInfoClent,
} = userSlice.actions

export default userSlice.reducer
