import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DocumentsState, TDocument } from './types'

const initialState: DocumentsState = {
  documents: [],
}

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocuments(state: DocumentsState, action: PayloadAction<TDocument[]>) {
      state.documents = action.payload
    },
    updateDocument(state: DocumentsState, action: PayloadAction<TDocument>) {
      state.documents = state.documents.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        }
        return item
      })
    },
  },
})

export const { setDocuments, updateDocument } = documentsSlice.actions

export default documentsSlice.reducer
