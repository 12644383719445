import { request } from 'api'
import { AppDispatch } from 'store/store'
import { setCountries } from './reducer'

export const getCountriesFromServer = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await request.get(`/countries`)
      dispatch(setCountries(response.data.data.countries))
    } catch (error) {
      console.log(error)
    }
  }
}
