import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TAuthState } from 'store/modules/auth/types'
import { TOksUser } from 'store/modules/users/types'

const initialState: TAuthState = {
  user: null,
  showMessageRP: false,
}

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state: TAuthState, action: PayloadAction<TOksUser | null>) {
      state.user = action.payload
    },
    setShowMessageRP(state: TAuthState, action: PayloadAction<boolean>) {
      state.showMessageRP = action.payload
    },
  },
})

export const { setUser, setShowMessageRP } = userSlice.actions

export default userSlice.reducer
