import React, { FC } from 'react'
import { colors } from 'styles/colors'
import logo from 'assets/images/formLogo.svg'
import { ReactComponent as Arrow } from 'assets/icons/backArrow.svg'
import { Link, useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import { useForm } from 'react-hook-form'
import { restorePassword } from 'store/modules/auth/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getShowMessageRP } from 'store/modules/auth/selectors'
import { Container, Form, Logo, Title, Text, Input, Button } from 'components/ui/auth'
import styled from 'styled-components'

export type TRestorePasswordInput = {
  email: string
}

const RestorePassword: FC = () => {
  const { register, handleSubmit } = useForm<TRestorePasswordInput>()
  const dispatch = useDispatch()
  const history = useHistory()

  const showMessage = useSelector(getShowMessageRP)

  const redirectToAuth = () => history.push(Routes.auth)

  const onSubmit = (data: TRestorePasswordInput) => {
    dispatch(restorePassword(data, redirectToAuth))
  }

  return (
    <Container>
      {showMessage && (
        <Message>
          <MessageText>Инструкция по восстановлению пароля отправлена на электронную почту</MessageText>
        </Message>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Logo src={logo} alt="Логотип ОКС" />
        <Title>Административная система</Title>
        <Text>Восстановление пароля</Text>
        <Input name="email" type="email" placeholder="Email" required={true} ref={register()} />
        <Button>Отправить</Button>
        <LinkContainer>
          <Arrow />
          <StyledLink to={Routes.home}>Назад</StyledLink>
        </LinkContainer>
      </Form>
    </Container>
  )
}

export default RestorePassword

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: 24px;
`
const StyledLink = styled(Link)`
  color: ${colors.grayThird};
  font-size: 18px;
  text-decoration: none;
  padding-left: 20px;
`
const Message = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${colors.blueMain};
  opacity: 0.8;
  margin: auto;
`
const MessageText = styled.span`
  font-size: 30px;
  color: #fff;
`
