export type TImagesState = {
  subtitles: string
  images: TBackgroundImage[]
  tags: TTag[]
  filteringTags: TTag[]
  openImage: {
    withEditBlock: boolean
    imageId: string | null
  }
  categories: TCategory[]
  image: TBackgroundImage | null
  isLoading: boolean
}

export type TBackgroundImage = {
  id: string
  createdAt: string
  imageOrientation: 'horizontal' | 'vertical'
  file: TBackgroundImageFile
  tags: TTag[]
  categories: TCategory[]
}

export type TBackgroundImageFile = {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  uploadedBy: number
  relativePath: string
  mimeType: string
  size: number
  originalName: string
  uploadedAt: string
  removedAt: string
}

export type TTag = {
  id: string
  name: string
}

export type TCategory = {
  id: string
  playerType: 'b2b' | 'b2c' | 'web'
  imageOrientation: 'horizontal' | 'vertical'
  backgroundImageType: 'playback' | 'pause'
  description: string
  images?: TBackgroundImage[]
}

export enum ESubtitlesLocalstorageKey {
  key = 'okc_subtitles',
}
