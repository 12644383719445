import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SharedState } from './types'

const initialState: SharedState = {
  countries: [],
}

const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setCountries(state: SharedState, action: PayloadAction<string[]>) {
      state.countries = action.payload
    },
  },
})

export const { setCountries } = sharedSlice.actions

export default sharedSlice.reducer
