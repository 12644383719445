const errorsMessage: { [k: number]: string } = {
  1203: 'Фонограмма с таким номером в каталоге уже существует',
  1408: 'Префикс с таким именем уже существует',
  1607: 'Лицензии не найдено',
  1612: 'Не найдено ставок для выбранного тарифа',
  1617: 'Нельзя удалить активную или истекшую лицензию',
  1618: 'Дата окончания действия лицензии должна быть больше или равна дате начала',
  1619: 'Выбранные даты пересекаются с существующими лицензиями',
}

export const getErrorMessage = (code: number): string => errorsMessage[code] || ''
