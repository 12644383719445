import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TSpot, TSpotInList, TSpotsState } from 'store/modules/spots/types'

const initialState: TSpotsState = {
  companyId: null,
  spot: null,
  spots: [],
}

const userSlice = createSlice({
  name: 'spots',
  initialState,
  reducers: {
    setSpot(state: TSpotsState, action: PayloadAction<TSpot | null>) {
      state.spot = action.payload
    },
    setCompanyId(state: TSpotsState, action: PayloadAction<string | null>) {
      state.companyId = action.payload
    },
    setSpots(state: TSpotsState, action: PayloadAction<TSpotInList[]>) {
      state.spots = action.payload
    },
  },
})

export const { setSpot, setSpots, setCompanyId } = userSlice.actions

export default userSlice.reducer
