import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FaqState, Question } from 'store/modules/faq/types'

const initialState: FaqState = {
  questions: [],
  question: null,
}

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setQuestions(state: FaqState, action: PayloadAction<Question[]>) {
      state.questions = action.payload
    },
    setActiveQuestion(state: FaqState, action: PayloadAction<Question | null>) {
      state.question = action.payload
    },
  },
})

export const { setQuestions, setActiveQuestion } = faqSlice.actions

export default faqSlice.reducer
