export const columnsWidths = '5% 8% 15% 15% 10% 10% 5% 5% 5% 5% 5% 12%'
export const tableTitles = [
  { id: 'catalogPhonogramNumber', name: 'Номер в каталоге', isSortable: true, width: 'minmax(70px, 1fr)', rule: 1 },
  { id: 'genre', name: 'Жанр', isSortable: false, width: 'minmax(80px, 1.8fr)', rule: 2 },
  { id: 'name', name: 'Название', isSortable: true, width: 'minmax(80px, 3fr)', rule: 3 },
  { id: 'singerName', name: 'Исполнитель', isSortable: true, width: 'minmax(80px, 3fr)', rule: 4 },
  { id: 'authorName', name: 'Музыка', isSortable: true, width: 'minmax(80px, 2fr)', rule: 5 },
  { id: 'authorTextName', name: 'Слова', isSortable: true, width: 'minmax(80px, 2fr)', rule: 6 },
  { id: 'isApproved', name: 'Проверено', isSortable: false, width: 'minmax(80px, 1fr)', rule: 7 },
  { id: 'musicFile', name: 'Караоке-файл', isSortable: false, width: 'minmax(80px, 1fr)', rule: 8 },
  { id: 'musicLyrics', name: 'Титры', isSortable: false, width: 'minmax(80px, 1fr)', rule: 9 },
  { id: 'isExplicit', name: 'ННЛ', isSortable: false, width: 'minmax(80px, 1fr)', rule: 10 },
  { id: 'vocalsMelody', name: 'Ведение мелодии', isSortable: false, width: 'minmax(80px, 1fr)', rule: 11 },
  { id: 'updatedAt', name: 'Дата обновления', isSortable: true, width: 'minmax(80px, 2.2fr)', rule: 12 },
]
