import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TChartsItem, TChartsState, TCompany, TGeoName, TGeoType } from './types'

const initialState: TChartsState = {
  chartsList: [],
  countryId: undefined,
  companiesList: [],
  geoTypesList: [],
  geoNamesList: [],
}

const userSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    setChartsList(state: TChartsState, action: PayloadAction<Array<TChartsItem>>) {
      state.chartsList = action.payload
    },
    setCountryId(state: TChartsState, action: PayloadAction<string | undefined>) {
      state.countryId = action.payload
    },
    setCompaniesList(state: TChartsState, action: PayloadAction<TCompany[]>) {
      state.companiesList = action.payload
    },
    setGeoTypesList(state: TChartsState, action: PayloadAction<TGeoType[]>) {
      state.geoTypesList = action.payload
    },
    setGeoNamesList(state: TChartsState, action: PayloadAction<TGeoName[]>) {
      state.geoNamesList = action.payload
    },
  },
})

export const { setChartsList, setCountryId, setCompaniesList, setGeoTypesList, setGeoNamesList } = userSlice.actions

export default userSlice.reducer
