import { toast } from 'react-toastify'
import { TError$ } from 'store/modules/error/types'
import { getErrorMessage } from './getErrorsMessadge'

export const errorNotify = (error: TError$) => {
  if (error.response && error.response.data) {
    toast.error(getErrorMessage(error.response.data.statusCode) || error.response.data.error)
    if (error.response.data.message && Array.isArray(error.response.data.message)) {
      error.response.data.message.forEach((m: string) => toast.error(m))
    } else {
      toast.error(error.response.data.message)
    }
  } else {
    toast.error('Ooops...')
  }
}
