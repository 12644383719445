import React, { FC } from 'react'
import logo from 'assets/images/logoInHeader.svg'
import styled from 'styled-components'
import { colors } from 'styles/colors'

export const Header: FC = () => {
  return (
    <SHeader>
      <Img src={logo} alt="Логотип ОКС" />
    </SHeader>
  )
}

const SHeader = styled.header`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.blueSecond};
  padding: 11px 8px;
`
const Img = styled.img``
