import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  TMixedPlaylistPhonograms,
  TPhonogramInBlacklist,
  TPlaylist,
  TPlaylistsState,
} from 'store/modules/playlists/types'
import { TPhonogram } from 'store/modules/phonogramms/types'

const initialState: TPlaylistsState = {
  listPlaylists: [],
  playlistById: null,
  playlistPhonograms: [],
  addingPhonogramsIds: [],
  deletingPhonogramsIds: [],
  listBlacklistPhonograms: [],
  addingInBlacklistPhonogramsIds: [],
  deletingInBlacklistPhonogramsIds: [],
}

const userSlice = createSlice({
  name: 'playlists',
  initialState,
  reducers: {
    setListPlaylists(state: TPlaylistsState, action: PayloadAction<[]>) {
      state.listPlaylists = action.payload
    },
    setPlaylistById(state: TPlaylistsState, action: PayloadAction<TPlaylist | null>) {
      state.playlistById = action.payload
    },
    setAddPhonogramsInPlaylistFromServer(
      state: TPlaylistsState,
      action: PayloadAction<Array<TMixedPlaylistPhonograms>>,
    ) {
      state.playlistPhonograms = action.payload
    },
    setAddPhonogramInPlaylist(state: TPlaylistsState, action: PayloadAction<TPhonogram>) {
      state.playlistPhonograms.push(action.payload)
    },
    setRemovePhonogramFromPlaylist(state: TPlaylistsState, action: PayloadAction<string | null>) {
      state.playlistPhonograms = state.playlistPhonograms.filter((item) => item && item.id !== action.payload)
    },
    setAddingPhonogramIds(state: TPlaylistsState, action: PayloadAction<string>) {
      state.addingPhonogramsIds.push(action.payload)
    },
    setDeletingPhonogramIds(state: TPlaylistsState, action: PayloadAction<string>) {
      state.deletingPhonogramsIds.push(action.payload)
    },
    clearAddingDeletingPhonogramIds(state: TPlaylistsState, action: PayloadAction<[]>) {
      state.addingPhonogramsIds = action.payload
      state.deletingPhonogramsIds = action.payload
    },
    setPhonogramsInBlacklistFromServer(state: TPlaylistsState, action: PayloadAction<Array<TPhonogramInBlacklist>>) {
      state.listBlacklistPhonograms = action.payload
    },
    setAddPhonogramInBlacklist(state: TPlaylistsState, action: PayloadAction<TMixedPlaylistPhonograms>) {
      state.listBlacklistPhonograms.push(action.payload)
    },
    setRemovePhonogramFromBlacklist(state: TPlaylistsState, action: PayloadAction<string | null>) {
      state.listBlacklistPhonograms = state.listBlacklistPhonograms.filter((item) => item && item.id !== action.payload)
    },
  },
})

export const {
  setListPlaylists,
  setPlaylistById,
  setAddPhonogramInPlaylist,
  setAddPhonogramsInPlaylistFromServer,
  setRemovePhonogramFromPlaylist,
  setAddingPhonogramIds,
  setDeletingPhonogramIds,
  clearAddingDeletingPhonogramIds,
  setPhonogramsInBlacklistFromServer,
  setAddPhonogramInBlacklist,
  setRemovePhonogramFromBlacklist,
} = userSlice.actions

export default userSlice.reducer
