import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Log, LogsState } from './types'
import { startOfDay, endOfDay } from 'date-fns'
import subMonths from 'date-fns/subMonths'

const initialState: LogsState = {
  logs: [],
  filters: {
    limit: 20,
    offset: 0,
    startDate: startOfDay(subMonths(new Date(), 1)).toISOString(),
    endDate: endOfDay(new Date()).toISOString(),
    oksUserId: null,
    lkUserId: null,
  },
  canLoadLogs: true,
}

const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    setLogs(state: LogsState, action: PayloadAction<Log[]>) {
      state.logs = action.payload
    },
    setLogsOnScroll(state: LogsState, action: PayloadAction<Log[]>) {
      state.logs = [...state.logs, ...action.payload]
    },
    setOffset(state: LogsState, action: PayloadAction<number>) {
      state.filters.offset = action.payload
    },
    setStartDate(state: LogsState, action: PayloadAction<string>) {
      state.filters.startDate = action.payload
    },
    setEndDate(state: LogsState, action: PayloadAction<string>) {
      state.filters.endDate = action.payload
    },
    setOksUserId(state: LogsState, action: PayloadAction<string | null>) {
      state.filters.oksUserId = action.payload
    },
    setLkUserId(state: LogsState, action: PayloadAction<string | null>) {
      state.filters.lkUserId = action.payload
    },
    setCanLoadLogs(state: LogsState, action: PayloadAction<boolean>) {
      state.canLoadLogs = action.payload
    },
  },
})

export const {
  setLogs,
  setLogsOnScroll,
  setOffset,
  setStartDate,
  setEndDate,
  setOksUserId,
  setLkUserId,
  setCanLoadLogs,
} = logsSlice.actions

export default logsSlice.reducer
