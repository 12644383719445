export const colors = {
  wight: '#F5F5F5',
  white: '#ffffff',
  grayThird: '#E0E0E0',
  grayFourth: '#F2F2F2',
  grayFifth: '#BDBDBD',
  graySixth: '#828282',
  grayTitle: '#3E4347',
  blueMain: '#2164A9',
  blueMainHover: '#1b4b7f',
  graySecond: '#282E33',
  blueHover: '#EAF4FF',
  redMain: '#EB5757',
  redHover: '#FFEDED',
  green: '#27AE60',
  lightBrown: 'rgba(242, 201, 76, 0.3)',
  pink: '#F9CDCD',
  lightGreen: 'rgba(39, 174, 96, 0.1)',
  blueSecond: 'rgba(33, 100, 169, 0.15)',
  // lightBrown: 'rgba(242, 201, 76, 0.3)',
}
