import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Territory, TerritoryState } from 'store/modules/territories/types'

const initialState: TerritoryState = {
  territoryShowPopUp: false,
  territories: [],
  territory: null,
}

const territorySlice = createSlice({
  name: 'territory',
  initialState,
  reducers: {
    setTerritoryShowPopUp(state: TerritoryState, action: PayloadAction<boolean>) {
      state.territoryShowPopUp = action.payload
    },
    setTerritory(state: TerritoryState, action: PayloadAction<Territory | null>) {
      state.territory = action.payload
    },
    setTerritories(state: TerritoryState, action: PayloadAction<Territory[]>) {
      state.territories = action.payload
    },
  },
})

export const { setTerritoryShowPopUp, setTerritory, setTerritories } = territorySlice.actions

export default territorySlice.reducer
